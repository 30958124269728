$sidebar-width-shrink: 48px;
$sidebar-width: 200px;
$header-height: 60px;
$color-primary:  #265284;
;
$shadow: 0px 1px 20px rgba(0,0,0,0.15);
$spacer: 1px;
$font-size-sm:                14px;


.sidebar {
  background-color: $color-primary;
  width: $sidebar-width-shrink;
  height: 100%;
  overflow: hidden;
  transition: width 0.3s ease;
  position: fixed;
  box-shadow: $shadow;

  &:hover {
    width: $sidebar-width;
  }

  & .sidebar-logo {
    height: $header-height + 1px;
    width: $sidebar-width;
    padding-left: $spacer;
    padding-right: $spacer;
    position: relative;
    border-bottom: 1px solid #fff;
  }

  & .ao_logo {
    width: $sidebar-width - (2*$spacer);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  & .ao_icon {
    width: $sidebar-width-shrink*(1/2);
    position: absolute;
    top: 50%;
    left: $sidebar-width-shrink*(1/4);
    transform: translateY(-50%);
    opacity: 1;
    transition: opacity 0.2s ease;
  }

  & .nav-link-text,
  & .ao_logo {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  & .nav-link-text {
    font-size: $font-size-sm;
    position: absolute;
    left: 48px;
    top: 14px;
  }

  &:hover .nav-link-text,
  &:hover .ao_logo {
    opacity: 1;
  }

  &:hover .ao_icon {
    opacity: 0;
  }

  .toggle {
    a {
      padding-left: 9px;
      text-transform: capitalize;
    }
    .ao-radio-button-group {
      opacity: 0;
      transition: opacity 0.2s ease;
      position: absolute;
      top: 12px;
      left: 48px;
    }
  }
  &:hover .toggle {
    .ao-radio-button-group {
      opacity: 1;
    }
  }

  .app-mode-title {
    transition: opacity 0.2s ease;
    &.expanded {
      position:absolute;
      opacity: 0;
      left: 9px;
    }
  }

  &:hover .app-mode-title {
    &.expanded {
      opacity: 1;
    }

    &.closed {
      opacity: 0
    }
  }

}

.nav-link {
  border-bottom: 1px solid #fff;
  min-width: $sidebar-width;
  overflow: hidden;

  & > nav-link-text {
    display: block;
    color: #fff;
    fill: #fff;
    padding: ($sidebar-width-shrink/3) ($sidebar-width-shrink/3) ($sidebar-width-shrink/5) (($sidebar-width-shrink/3) - 3px);
    position: relative;

    &.router-link-active,
    &:hover,
    &:focus {
      text-decoration: none;
      color: $color-primary;
      background-color: #fff;

      & > svg {
        fill: $color-primary
      }
    }

    &.Transactions .nav-link-text {
      top: 3px;
    }

   
    &.Transactions, &.Vendors {
      .custom-glyph {
       // background: url('../assets/icons/transactions_white.svg') no-repeat center;
        background-size: contain;

        &:before {
          opacity: 0;
        }
      }

      &.router-link-active,
      &:hover,
      &:focus {
        .custom-glyph {
       //   background-image: url('../assets/icons/transactions_blue.svg');
        }
      }
    }

   
  }
}
