

$color-primary:               #00a38b;
$color-primary-dark:          darken($color-primary, 5);
$color-dark:                  #292F3B;

$color-white:                 #fff;
$color-green:                 #17a66c;
$color-green-light:           #def7ed;
$color-green-dark:            #08774a;
$color-blue:                  #00b0e4;
$color-blue-light:            #e7f8fd;
$color-blue-dark:             #007598;
$color-yellow:                #f9c228;
$color-yellow-light:          #fff8e4;
$color-yellow-dark:           #906a01;
$color-red:                   #d93240;
$color-red-light:             #ffe1e3;
$color-red-dark:              #a11e29;

$color-success:               $color-green;
$color-success-light:         $color-green-light;
$color-success-dark:          $color-green-dark;
$color-info:                  $color-blue;
$color-info-light:            $color-blue-light;
$color-info-dark:             $color-blue-dark;
$color-caution:               $color-yellow;
$color-caution-light:         $color-yellow-light;
$color-caution-dark:          $color-yellow-dark;
$color-destructive:           $color-red;
$color-destructive-light:     $color-red-light;
$color-destructive-dark:      $color-red-dark;

// Ample Shades of Gray
$color-gray-10:               #474a4c;
$color-gray-20:               #5e6366;
$color-gray-30:               #757b80;
$color-gray-40:               #8d9499;
$color-gray-50:               #aaadb3;
$color-gray-60:               #c6c9cc;
$color-gray-70:               #d9dcde;
$color-gray-80:               #ebecf0;
$color-gray-90:               #f7f7f9;

//
// Spacing & Sizing
//

$spacer-px:                   15px;
$spacer-micro:                .25rem;
$spacer-xs:                   .5rem;
$spacer-sm:                   .75rem;
$spacer:                      1rem;
$spacer-lg:                   1.5rem;

//
// Typography
//

$font-family-primary:         'Lato', arial, sans-serif;

$font-size-base:              16px; // This becomes 1rem
$font-size-xs:                12px;
$font-size-sm:                14px;
$font-size-lg:                20px;
$font-size-xl:                28px;
$font-size-xxl:               34px;

$font-weight-light:           200;
$font-weight-bold:            700;

$font-color-base:             $color-gray-10;
$font-color-secondary:        $color-gray-30;
$font-color-error:            $color-destructive;

$font-color-link:             $color-primary;
$font-color-link-hover:       $color-primary-dark;

$line-height-base:            1.4;

//
// Layout
//

$layout-background-color:     $color-gray-90;

$zindex-base:                 1000;
$zindex-dropdown:             1010;
$zindex-header-toolbar:       1020;
$zindex-modal-backdrop:       1030;
$zindex-modal:                1031;
$zindex-alert:                1040;
$zindex-spinner:              1050;
$zindex-tooltip:              1060;
$zindex-popover:              1060;

$header-toolbar-height:       60px;

//
// General UI Styles
//

$ui-border-color-base:        $color-gray-70;
$border-gray-50:              1px solid $color-gray-60;

$border-radius-base:          3px;

$shadow-none:                 0 0 0 rgba(0,0,0,0);
$shadow:                      0px 1px 20px rgba(0,0,0,0.15);
$shadow-dramatic:             0px 5px 40px rgba(0,0,0,0.35);
$shadow-subtle:               0px 2px 3px rgba(0, 0, 0, 0.05);

$shadow-inset:                inset 0 2px 3px rgba(0, 0, 0, 0.05);

$transition-base:             0.2s ease;

$header-icon-height:          24px;

$browser-scrollbar-width:     17px; // https://codepen.io/sambible/post/browser-scrollbar-widths

//
// Inputs & Buttons
//

$input-color:                 $color-gray-20;
$input-height-base:           2.25rem;
$input-height-sm:             2rem;
$input-height-lg:             2.5rem;
// ^ This value will help us ensure consistent heights of inputs and buttons
$input-border-width:          1px;
$input-border-color:          $color-gray-60;
$input-border-radius:         $border-radius-base;

$input-border-color-error:    $color-destructive;

$input-focus-border-color:    $color-primary;
$input-focus-shadow:          0 0 0 1px transparentize($color-primary, 0.5);
$input-focus-shadow-error:    0 0 1px transparentize($color-destructive, 0.1);


$input-label-margin:          0 0 $spacer-xs;

//
// Tables
//

$table-font-size:             $font-size-sm;
$table-line-height:           $line-height-base;
$table-cell-padding:          $font-size-base/2;
$table-border-color:          $color-gray-70;
$table-row-background-shaded: $color-gray-90;

$table-input-vertical-cell-padding: 0.25rem;

.step ul li {
  display: inline-block;
  position: relative;
  z-index: 5;
  margin-left: 20px;
  margin-right: 20px;
  cursor: inherit;

  &:first-child {
    margin-left: 0;
  }

  &:after {
    content: "";
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 40px solid #265284;
    position: absolute;
    right: -40px;
    top: 0;
  }

  & .router-link-exact-active, .router-link-active, .active {
    background: #265284;
    color: #fff;

    &:after {
      content: "";
      border-top: 39px solid transparent;
      border-bottom: 39px solid transparent;
      border-left: 39px solid #265284;
    }
  }

  & > * {
    width: 100%;
    border-radius: 0;
    border: 1px solid #265284;
    border-right: 0;
    flex-direction: column;
    align-items: baseline;
    height: auto;
    padding: 10px 50px 20px 20px;
    @media (max-width: 1210px) {
      padding-right: 40px;
    }
    @media (max-width: 1170px) {
      padding-right: 30px;
    }
    @media (max-width: 1125px) {
      padding-right: 20px;
    }
    @media (max-width: 1085px) {
      padding-right: 10px;
    }
    @media (max-width: 1045px) {
      padding-right: 0;
    }

    &.pad-r-30 {
      padding-right: 30px;
    }

    height: 80px;
    font-size: 24px;
    color: #000;
    position: relative;
    background: #fff;

    &:after {
      content: "";
      border-top: 39px solid transparent;
      border-bottom: 39px solid transparent;
      border-left: 39px solid #fff;
      position: absolute;
      right: -39px;
      top: 0;
      z-index: 5;
    }
  }

  & + li {
    margin-left: -18px;

    &:before {
      content: "";
      border-top: 40px solid transparent;
      border-bottom: 40px solid transparent;
      border-left: 40px solid #265284;
      position: absolute; left: 0; top: 0;
      z-index: 5;
    }

    & > * {
      padding-left: 60px;
      border-left: 0;

      &:before {
        content: "";
        border-top: 39px solid transparent;
        border-bottom: 39px solid transparent;
        border-left: 39px solid #f7f7f9;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
      }
    }
  }

  span {
    font-size: 12px;
  }
}
